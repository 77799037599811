import React, { useState, useEffect } from "react";

import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import MusicPlayer from "../MusicPlayer/musicPlayer";

import * as classes from "./Lookbook.module.css";
import LookbookItems from "./LookbookItems";

const Lookbook = (props) => {
  // Image state: full size + thumbnail arrays + current index
  const [imgState, setImgState] = useState({
    imgArr: [],
    currIndex: 0,
  });

  // React Transition Main Image State
  const [showArchive, setShowArchive] = useState(false);

  // Fetching data for image state
  useEffect(() => {
    let imageArray = [];
    props.data.fullsize.edges.map((edge) => imageArray.push(edge.node));

    setImgState({
      imgArr: imageArray,
      currIndex: 0,
    });
  }, [props.data.fullsize.edges]);

  // Active index function
  const activeIndexHandler = (index) => {
    setImgState((prevState) => ({
      imgArr: prevState.imgArr,
      currIndex: index,
    }));
  };

  // Change Index Function
  const increaseIndex = () => {
    if (imgState.currIndex === imgState.imgArr.length - 1) {
      setImgState((prevState) => ({
        imgArr: prevState.imgArr,
        currIndex: 0,
      }));
    } else {
      setImgState((prevState) => ({
        imgArr: prevState.imgArr,
        currIndex: prevState.currIndex + 1,
      }));
    }
  };

  const decreaseIndex = () => {
    if (imgState.currIndex === 0) {
      setImgState((prevState) => ({
        imgArr: prevState.imgArr,
        currIndex: imgState.imgArr.length - 1,
      }));
    } else {
      setImgState((prevState) => ({
        imgArr: prevState.imgArr,
        currIndex: prevState.currIndex - 1,
      }));
    }
  };

  // Toggle Archive Dropdown
  const toggleArchiveHandler = () => {
    setShowArchive((prev) => !prev);
  };

  // Archive navigation conditional classes
  const archiveNavClass = showArchive
    ? `${classes.archiveLinks}`
    : `${classes.archiveLinks} ${classes.hide}`;

  // Season Text
  const pathname = props.location.pathname;
  let seasonTitle;
  if (pathname.includes("2020SS")) {
    seasonTitle = "Spring / Summer 2020";
  }
  if (pathname.includes("2020FW")) {
    seasonTitle = "Fall / Winter 2020";
  }

  return (
    <>
      <div className={classes.lookbookContainer}>
        {imgState.imgArr.length > 0 && (
          <>
            {/* CONTAINER 1 */}
            {/* <SwitchTransition>
            <CSSTransition
              key={imgState.currIndex}
              timeout={{
                appear: 500,
                enter: 500,
                exit: 0,
              }}
              classNames="fade"
            > */}
            <div
              className={classes.imageMainContainer}
              key={imgState.currIndex}
            >
              <GatsbyImage
                image={getImage(imgState.imgArr[imgState.currIndex])}
                alt="lookbook-main-picture"
              />
            </div>
            {/* </CSSTransition>
          </SwitchTransition> */}

            {/* CONTAINER 2 */}
            <div className={classes.imageNavContainer}>
              {imgState.imgArr.map((node, i) => (
                <LookbookItems
                  image={getImage(node)}
                  id={node.id}
                  activeIndexHandler={activeIndexHandler}
                  key={node.id}
                  index={i}
                />
              ))}
            </div>
            {/* CONTAINER 3 */}
            <div className={classes.productInfo}>
              <p>
                {parseInt(imgState.currIndex) + 1}/{imgState.imgArr.length}
                <br />
                <b>{seasonTitle}</b>
                <br />
                <Link to="/shop">
                  {imgState.imgArr[imgState.currIndex].name.slice(4)}
                </Link>
              </p>
            </div>
            {/* CONTAINER 4 */}
            <div className={classes.lookbookLinks}>
              <div className={classes.archiveDropdown}>
                <button
                  onClick={toggleArchiveHandler}
                  className={classes.archiveLabel}
                >
                  Archive
                </button>
                <ul className={archiveNavClass}>
                  <li>
                    <Link to="/lookbook/2020SS-1">2020SS-1</Link>
                  </li>
                  <li>
                    <Link to="/lookbook/2020SS-2">2020SS-2</Link>
                  </li>
                  <li>
                    <Link to="/lookbook/2020SS-3">2020SS-3</Link>
                  </li>
                  <li>
                    <Link to="/lookbook/2020SS-4">2020SS-4</Link>
                  </li>
                </ul>
              </div>

              <div className={classes.prevNext}>
                <button onClick={decreaseIndex}>Prev</button>
                <button onClick={increaseIndex}>Next</button>
              </div>
            </div>
          </>
        )}
      </div>
      {pathname.includes("2020SS-1") && <MusicPlayer />}
    </>
  );
};

export default Lookbook;
