import React, { useRef, useState } from "react";
import * as classes from "./musicPlayer.module.css";
// import audio from "../../audio/fatjon-nujabes-ask.mp3";
import audio from "../../audio/how-you-feel.mp3";

const MusicPlayer = () => {
  // Audio Player
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayer = useRef();

  const play = () => {
    audioPlayer.current.play();
    setIsPlaying(true);
  };
  const pause = () => {
    audioPlayer.current.pause();
    setIsPlaying(false);
  };

  return (
    <div className={classes.musicPlayer}>
      <div className={classes.musicPlayerInner}>
        <audio id="audio" src={audio} ref={audioPlayer} />
        {!isPlaying ? (
          <button onClick={play} id="playPauseBtn">
            Play
          </button>
        ) : (
          <button onClick={pause} id="playPauseBtn">
            Pause
          </button>
        )}
        <small>Fat Jon, Nujabes - How You Feel</small>
      </div>
    </div>
  );
};

export default MusicPlayer;
