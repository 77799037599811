import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo/seo";
import Lookbook from "../components/Lookbook/Lookbook";

const LookbookPage = (props) => {
  console.log(props.path);

  return (
    <>
      <Seo title="Lookbook" description="Lookbooks for Goriot" />
      <Lookbook data={props.data} location={props.location} />
    </>
  );
};

export const pageQuery = graphql`
  query ($link: String!) {
    thumbnails: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativeDirectory: { eq: $link } }
    ) {
      edges {
        node {
          name
          id
          childImageSharp {
            gatsbyImageData(
              width: 300
              blurredOptions: { width: 10 }
              placeholder: NONE
            )
          }
        }
      }
    }
    fullsize: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativeDirectory: { eq: $link } }
    ) {
      edges {
        node {
          name
          id
          childImageSharp {
            gatsbyImageData(
              width: 750
              blurredOptions: { width: 5 }
              placeholder: NONE
              quality: 100
            )
          }
        }
      }
    }
  }
`;

export default LookbookPage;
